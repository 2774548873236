<template>
  <div class="analysis-wrap">
    <projects-analysis v-if="tabIndex==0" @changeTab="changeTab"></projects-analysis>
    <revenue-analysis v-if="tabIndex==1" @changeTab="changeTab"></revenue-analysis>
    <member-analysis v-if="tabIndex==2" @changeTab="changeTab"></member-analysis>
  </div>

</template>

<script>
export default {
  name: 'analysis',
  components: {
    'projects-analysis':() => import('./projects-analysis.vue'),
    'revenue-analysis':() => import('./revenue-analysis.vue'),
    'member-analysis':() => import('./member-analysis.vue')
  },
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      tabIndex:0
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.$store.commit('updateIsShowTabBar',true)
    this.$store.commit("updateIsShowPickStore",true)
  },
  methods: {
    changeTab(index){
      this.tabIndex = index
    }
  }
}
</script>
<style lang="scss" scoped>
  .analysis-wrap {
      display:flex;
      flex-direction:column;
      height:100%;
  }
</style>
